import { useState, useCallback } from "react";
import axiosInstance from "../services/axiosIntercept";
import { CurrencySettingData } from "../Components/CurrencySettings/currency_settings";

export const useCurrencySettings = (programId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CurrencySettingData[]>([]);

  const fetchSettings = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `/v1/currency-settings/${programId}`,
      );
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch currency settings:", error);
    } finally {
      setIsLoading(false);
    }
  }, [programId]);

  const saveSettings = useCallback(
    async (settings: CurrencySettingData[]) => {
      try {
        await axiosInstance.post(
          `/v1/currency-settings/${programId}`,
          settings,
        );
        return true;
      } catch (error) {
        console.error("Failed to save currency settings:", error);
        return false;
      }
    },
    [programId],
  );

  return {
    isLoading,
    data,
    fetchSettings,
    saveSettings,
  };
};
