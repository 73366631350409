import React, { useState, useEffect, useMemo } from "react";
import "./create_voucher_page.css";
import "./param.css";
import "./voucher.css";
import { ReactComponent as VoucherCashIcon } from "./../../assets/symbols/voucher-cash.svg";
import { ReactComponent as VoucherDiscountIcon } from "./../../assets/symbols/voucher-discount.svg";
import { ReactComponent as VoucherEventIcon } from "./../../assets/symbols/voucher-event.svg";
import { ReactComponent as VoucherGiveawayIcon } from "./../../assets/symbols/voucher-giveaway.svg";
import Step from "../../Components/Step/step";
import { Switch } from "antd";
import DiscountVoucher from "../../Components/DiscountVoucher/discount_voucher";
import CashVoucher from "../../Components/CashVoucher/cash_voucher";
import Giveaway from "../../Components/Giveaway/giveaway";
import EventPass from "../../Components/EventPass/event_pass";
import Info_Panel from "../../Components/InfoPanel/info_panel";
import { useSelector } from "react-redux";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import useGetVouchers from "../../Hooks/getVouchers";
import useGetVerified from "../../Hooks/getVerified";
import { VoucherType, VOUCHER_TERMS_N_CONDITIONS } from "../../utils/constants";
import {
  warningWithTimeout,
  getVoucherTypeDescription,
} from "../../utils/helpers";
import * as selectors from "../../selectors/selectors";
import * as actions from "../../actions/actions";
import { handleActionNotification } from "../../utils/utils";
import Deal from "../../Components/Deal/deal";
import { calculateDuration } from "../../utils/utils";
import Title from "../../Components/Title/title";
import InnerScroll from "../../Components/InnerScroll/inner_scroll";
import Separator from "../../Components/Separator/separator";
import Label from "../../Components/Label/label";
import Helper from "../../Components/Helper/helper";
import DetailedPreview from "../../Components/DetailedPreview/detailed_preview";
import Explainer from "../../Components/Explainer/explainer";
import { DateTime } from "luxon";
import AppButton from "../../Components/AppButton/app_button";
import GuidedTour from "../../Components/GuidedTour";
import { DayPicker } from "react-day-picker";
import Uploader from "../../Components/Uploader/uploader";
import axiosInstance from "../../services/axiosIntercept";
import StepsNav from "../../Components/StepsNav/steps_nav";
import AppInput from "../../Components/AppInput/app_input";
import classNames from "classnames";
import useGenerateStamps from "../../Hooks/generateStamps";
import TooltipContext from "../../Components/TooltipContext/tooltip_context";
import Tooltip from "../../Components/Tooltip/tooltip";
import useLimitCheck from "../../Hooks/getLimitCheck";
import { Modal, Table } from "antd";
import { CurrencySettings } from "../../Components/CurrencySettings/currency_settings";
import { useCurrencySettings } from "../../Hooks/useCurrencySettings";

/**
 *
 * @param {object} props
 * @param {import("../../types").Voucher} props.data
 * @param {() => void} props.manage_Page
 * @param {"edit"|"restart"|"create"|"blocked"} props.mode
 * @returns {import("react").ReactNode}
 */
function CreateVoucher_Page({ manage_Page, mode, data }) {
  const dispatch = useDispatch();

  const uuid = useSelector(selectors.getUuid);
  const accountUuid = useSelector(selectors.getAccountUuid);
  console.log("AccountUUID", accountUuid);
  const getVouchers = useGetVouchers(accountUuid);
  const getVerified = useGetVerified();
  const getLimitCheck = useLimitCheck();

  const isAuthenticated = useSelector(selectors.getIsAuthenticated);
  const isLoggedOut = useSelector(selectors.getIsLoggedOut);

  const programName = useSelector(selectors.getSelectedProgramName);
  const programLocation = useSelector(selectors.getSelectedProgramLocation);

  const businessImageName = useSelector(selectors.getBusinessImageName);
  const businessBrandColor = useSelector(selectors.getBusinessBrandColor);
  const selected_lp = useSelector(selectors.getSelectedLp);
  const selected_lp_uuid = useSelector(selectors.getSelectedLpUuid);
  const cash_voucher = useSelector(selectors.getCashVoucher);
  const discount_voucher = useSelector(selectors.getDiscountVoucher);
  const event_pass = useSelector(selectors.getEventPass);
  const event_address = useSelector(selectors.getEventAddress);
  const giveaway = useSelector(selectors.getGiveaway);
  const special_address = useSelector(selectors.getSpecialAddress);
  const currency = useSelector(selectors.getCurrency);
  const generateStamp = useGenerateStamps();

  //* Setting useState
  const [focus, setFocus] = useState(0); //To make border in components
  const [display, setDisplay] = useState(
    data ? data.voucherTypeId : VoucherType.DISCOUNT,
  ); //To set the voucher type
  const [completed, setComplete] = useState({
    1: false,
    2: false,
    3: false,
  });
  const [selectedImageSHA, setSelectedImageSHA] = useState(
    data ? data.imageId : null,
  );
  const stored_currency = useSelector(selectors.getCurrency);

  /**
   * @typedef {object} State
   * @property {string|null} name - The name of the campaign.
   * @property {string|null} description - The description of the campaign.
   * @property {object} req - The requirements of the campaign.
   * @property {string|null} req.a - The first requirement.
   * @property {string|null} req.b - The second requirement.
   * @property {string|null} image - The ID of the image associated with the campaign.
   * @property {Date|null} startDate - The start date of the campaign in ISO format.
   * @property {Date|null} endDate - The end date of the campaign in ISO format.
   * @property {boolean} autoVoucher - Whether the campaign has an auto voucher.
   * @property {number|null} no_visit - The number of visits/stamps for the auto voucher.
   * @property {boolean} ovpu - Whether the auto voucher is for one-time use.
   * @property {boolean} referralRewardVoucher - Whether the voucher is a reward voucher
   * @property {number} [referralRewardVoucherLevel] - Number of referees of the reward voucher
   * @property {string|undefined} termsNConditions - The terms and conditions of the campaign.
   * @property {string|undefined} termsNConditionsURL - The terms and conditions of the campaign.
   * @property {boolean} memberSelfStamping - Whether the member can self-stamp
   * @property {number} memberSelfStampingWaitHours - The number of hours to wait before self-stamping
   * @property {boolean} memberReceiptScanning - Whether member can scan receipts to accumelate stamps
   * @property {number} memberReceiptStampAmount - Amount to receive a stamp
   */
  const [value, setValue] = useState(
    /** @type {State} */ ({
      name: data ? data.campaignName : null,
      description: data ? data.description : null,
      req: {
        a: data ? data.requirements.requirements[0] : null,
        b: data ? data.requirements.requirements[1] : null,
      },
      image: data ? data.imageId : null,
      //TODO divide make seperate objects for both as they are overwriting each other
      startDate: data?.startDate ? new Date(data.startDate) : null,
      endDate: data?.expiryDate ? new Date(data.expiryDate) : null,
      // Auto-voucher related
      autoVoucher: data ? data.autoVoucher : false,
      no_visit: data ? data.autoVoucherNumberOfVisits : null,
      ovpu: data ? data.autoVoucherIsOneTimeUse : false,

      // Reward-voucher related
      referralRewardVoucher: data ? data.referralRewardVoucher : false,
      referralRewardVoucherLevel: data ? data.referralRewardVoucherLevel : null,

      termsNConditions: data?.termsNConditions,
      termsNConditionsURL: data?.termsNConditionsURL,

      memberSelfStamping: data ? data.memberSelfStamping : false,
      memberSelfStampingWaitHours: data ? data.memberSelfStampingWaitHours : 24,

      memberReceiptScanning: data ? data.memberReceiptScanning : false,
      memberReceiptStampAmount: data ? data.memberReceiptStampAmount : null,

      currency: stored_currency ? stored_currency : null,
    }),
  ); // To set all values in this.

  const totalMobileSteps = 4;
  const [mobileStep, setMobileStep] = useState(0);
  const handleStepsNavPrevClick = () => {
    setMobileStep(Math.max(mobileStep - 1, 0));
  };

  const handleStepsNavNextClick = () => {
    setMobileStep(Math.min(mobileStep + 1, totalMobileSteps - 1));
  };

  useEffect(() => {
    setValue((state) => ({
      ...state,
      image: selectedImageSHA,
    }));
  }, [selectedImageSHA]);

  const voucherHasExpired = useMemo(() => {
    return value.endDate
      ? DateTime.fromJSDate(value.endDate) < DateTime.now().startOf("day")
      : false;
  }, [value.endDate]);

  useEffect(() => {
    if (mode === "create") {
      const campaignName =
        (giveaway ? `${giveaway} ` : "") + getVoucherTypeDescription(display);
      setValue((prev) => ({ ...prev, name: campaignName }));
    }
  }, [mode, giveaway, display]);

  //* Setting useEffect
  useEffect(() => {
    if (!isAuthenticated && !isLoggedOut) getVerified();

    console.log(value?.image);
    if (
      !completed[2] &&
      value.name !== null &&
      value.description != null &&
      (value.req.a != null || value.req.b != null) &&
      value.image != null
    ) {
      setComplete({ ...completed, 2: true });
    } else if (
      !completed[3] &&
      value.startDate !== null &&
      value.endDate != null
    ) {
      setComplete({ ...completed, 3: true });
      console.log("helo");
    } else {
      setComplete({
        1: false,
        2: false,
        3: false,
      });
    }
  }, [value]);

  const options_visit = [4, 5, 7, 8, 10, 15, 20];
  const options_no_of_referees = [2, 10, 20, 30, 40];
  const options_stampCD = [4, 5, 7, 8, 12, 24, 48];
  const options_dropdown_currencies = [{ value: "HKD", description: "HKD" }];

  const handleCreateVoucher = (selected_lp) => {
    const ver_btn = document.getElementById("cr-btn-vh");
    ver_btn.disabled = true;

    const setErrorBorder = (fieldId) => {
      const field = document.getElementById(fieldId);
      console.log("set error" + field);
      if (field) {
        field.classList.add("error-border");
      }
    };

    const clearErrorBorders = () => {
      const errorFields = document.querySelectorAll(".error-border");
      errorFields.forEach((field) => field.classList.remove("error-border"));
    };

    clearErrorBorders();

    //TODO first check all the values are filled or not.
    console.log(selectedImageSHA);
    console.log(value.autoVoucher, value.ovpu);
    const program_id = +selected_lp;
    if (value.name === null) {
      warningWithTimeout({
        message: "Please enter the Campaign voucher name",
      });
      ver_btn.disabled = false;
      setErrorBorder("voucher-name");
    } else if (!value.description) {
      setErrorBorder("voucher-description");
      warningWithTimeout({
        message: "Please enter the description",
      });
      ver_btn.disabled = false;
    } else if (value.req.a === null) {
      warningWithTimeout({
        message: "Please enter the requirement",
      });
      ver_btn.disabled = false;
      setErrorBorder("voucher-requirement");
    } else if (value.startDate === null) {
      warningWithTimeout({
        message: "Please enter the start time",
      });
      ver_btn.disabled = false;
      setErrorBorder("voucher-start-date");
    } else if (value.endDate === null) {
      warningWithTimeout({
        message: "Please enter the end time",
      });
      ver_btn.disabled = false;
      setErrorBorder("voucher-end-date");
    } else if (value.autoVoucher === true && value.no_visit === null) {
      warningWithTimeout({
        message:
          "Please enter the number of stamps required for the autovoucher redemption.",
      });
      ver_btn.disabled = false;
    } else if (
      value.referralRewardVoucher === true &&
      !value.referralRewardVoucherLevel
    ) {
      warningWithTimeout({
        message: "Please enter the number of referees for the reward voucher.",
      });
      ver_btn.disabled = false;
    } else {
      if (selectedImageSHA) {
        /** @type {import("../../types").Voucher} */
        const body = {
          campaignName: value.name,
          imageId: value.image,
          startDate: DateTime.fromJSDate(value.startDate)
            .set({ hour: 0, minute: 0, second: 1 })
            .toISO(),
          expiryDate: DateTime.fromJSDate(value.endDate)
            .set({ hour: 23, minute: 59, second: 59 })
            .toISO(),

          location: selected_lp?.physicalAddress
            ? selected_lp?.physicalAddress.split(", ")[2]
            : "",
          requirements: {
            requirements: [value.req.a, value.req.b],
          },

          termsNConditionsURL: value.termsNConditionsURL,
          backgroundColor: "",

          description: value.description,
          termsNConditions: VOUCHER_TERMS_N_CONDITIONS.replace(
            "[address]",
            programLocation,
          ),

          isValid: true, //TODO: This needs to be set as false when API is handled in handleValidity
          programId: program_id,

          backgroundImageId:
            "281902f594c2b35a6bf6986a42edbe14b9f7b325847876c6dbad0622d6b6361a",

          voucherTypeId: display,

          valueDescription:
            display === VoucherType.DISCOUNT
              ? `${discount_voucher * 100}% OFF`
              : display === VoucherType.CASH
                ? `${cash_voucher} ${currency} OFF`
                : "",

          value: display === VoucherType.CASH ? +cash_voucher : 0, // cash voucher

          productServiceDescription:
            display === VoucherType.DISCOUNT ||
            display === VoucherType.CASH ||
            display === VoucherType.GIVEAWAY
              ? giveaway
              : "", // Save product / service description

          specialAddress:
            display === VoucherType.GIVEAWAY ? special_address : "", // Giveaway

          eventCode: display === VoucherType.EVENT_PASS ? event_pass : "", // Event Pass
          eventAddress: display === VoucherType.EVENT_PASS ? event_address : "", // Event Pass

          ...(value.autoVoucher
            ? {
                autoVoucher: true,
                autoVoucherIsOneTimeUse: value.ovpu,
                autoVoucherNumberOfVisits: +value.no_visit,
                memberSelfStamping: value.memberSelfStamping,
                memberSelfStampingWaitHours: 24,
                memberReceiptScanning: value.memberReceiptScanning,
                memberReceiptStampAmount: +value.memberReceiptStampAmount,

                referralRewardVoucher: false,
                referralRewardVoucherLevel: 0,
              }
            : value.referralRewardVoucher
              ? {
                  autoVoucher: false,
                  autoVoucherIsOneTimeUse: false,
                  autoVoucherNumberOfVisits: 0,

                  referralRewardVoucher: true,
                  referralRewardVoucherLevel: value.referralRewardVoucherLevel,
                }
              : {
                  autoVoucher: false,
                  autoVoucherIsOneTimeUse: false,
                  autoVoucherNumberOfVisits: 0,

                  referralRewardVoucher: false,
                  referralRewardVoucherLevel: 0,
                }),
        };

        if (data && data.id) {
          body.id = data.id;
        }

        axiosInstance
          .post(
            `${process.env.REACT_APP_API_URL}v2/voucher/create/update`,
            body,
            {
              withCredentials: true,
              headers: {
                "api-key": process.env.REACT_APP_API_KEY,
                "content-type": "application/json",
              },
            },
          )
          .then(async (response) => {
            await getVouchers(program_id);
            notification.success({
              message:
                mode === "restart"
                  ? "Voucher campaign restarted successfully"
                  : mode === "edit"
                    ? "Voucher campaign updated successfully"
                    : "Voucher campaign created successfully",
            });
            ver_btn.disabled = false;

            console.log("Voucher created successfully:", response.data);
            localStorage.setItem("NewUser", false);
            // Clear store //TODO can make a hook to clear storage.
            dispatch(actions.setCashVoucher(null));
            dispatch(actions.setDiscountVoucher(null));
            dispatch(actions.setEventPass(null));
            dispatch(actions.setEventAddress(null));
            dispatch(actions.setGiveaway(null));
            dispatch(actions.setSpecialAddress(null));

            generateStamp(selected_lp, true, selected_lp_uuid);

            getLimitCheck();

            manage_Page();
          })
          .catch((error) => {
            console.error("Error updating voucher campaign:", error);
            notification.error({
              message: "Error while creating voucher campaign.",
              description:
                error?.response?.data?.message?.message ||
                "An unexpected error occurred. Please try again.",
            });
            ver_btn.disabled = false;
          });
      } else {
        warningWithTimeout({
          message: "Please upload image",
          description:
            "Without the image, we cannot created voucher. Please try to create voucher after uploading the image.",
        });
        ver_btn.disabled = false;
        setErrorBorder("upload-image");
      }
    }
  };

  const checkVoucherType = () => {
    if (display === VoucherType.DISCOUNT && discount_voucher) return "0";
    else if (display === VoucherType.CASH && cash_voucher) return "0";
    else if (display === VoucherType.GIVEAWAY && (giveaway || special_address))
      return "0";
    else if (
      display === VoucherType.EVENT_PASS &&
      (event_address || event_pass)
    )
      return "0";
    else return "1";
  };

  const checkDetils = () => {
    if (
      value.name &&
      value.description &&
      value.image &&
      (value.req.a || value.req.b)
    )
      return "0";
    else return "2";
  };

  const getValue = (display) => {
    switch (display) {
      case VoucherType.DISCOUNT:
        return (
          <>
            {discount_voucher * 100}% OFF {<br />}{" "}
          </>
        );
      case VoucherType.CASH:
        return (
          <>
            {cash_voucher} {currency} OFF {<br />}{" "}
          </>
        );
      case VoucherType.GIVEAWAY:
        return <>Giveaway</>;
      case VoucherType.EVENT_PASS:
        return <>Event Pass</>;
      default:
        return "NULL";
    }
  };

  const getTitle = (display) => {
    switch (display) {
      case VoucherType.DISCOUNT:
        return <>{giveaway}</>;
      case VoucherType.CASH:
        return <>{giveaway}</>;
      case VoucherType.GIVEAWAY:
        return <>{giveaway}</>;
      case VoucherType.EVENT_PASS:
        return <>{event_address}</>;
      default:
        return "NULL";
    }
  };
  const checkSchedule = () => {
    if (
      value.startDate &&
      value.endDate &&
      (!value.autoVoucher || (value.autoVoucher && value.no_visit))
    )
      return "0";
    else return "3";
  };

  const campaignName = () => {
    if (mode === "create") {
      if (giveaway) {
        return (
          `${giveaway ? giveaway : ""} ` + getVoucherTypeDescription(display)
        );
      } else {
        return (
          `${event_pass ? event_pass : ""} ` +
          getVoucherTypeDescription(display)
        );
      }
    } else {
      return value.name;
    }
  };

  const [currencySettingsVisible, setCurrencySettingsVisible] = useState(false);

  const {
    data: currencyData,
    fetchSettings,
    saveSettings,
  } = useCurrencySettings(data?.id);

  return (
    <div className="create-voucher">
      <div className="voucher container">
        <h1 className="voucher__heading">
          <Title>Your voucher campaign</Title>
        </h1>
        <div className="voucher__body">
          <div
            className={`voucher__section${
              focus === 1 ? " voucher__section--focus" : ""
            }${mobileStep === 0 ? " membership__section--mobile-current" : ""}`}
            onClick={() => {
              setFocus(1);
            }}
          >
            <fieldset disabled={mode === "restart"}>
              <div className="voucher-type-selection">
                <div className="voucher__sub-heading">
                  <Step number={checkVoucherType()}>
                    <Title secondary>
                      Voucher&nbsp;
                      <span className="nobr">
                        Type
                        <Explainer size="20">
                          Select the type of voucher
                          <br />
                          you want to create.
                          <br />
                          Visit our FAQ page for more details.
                        </Explainer>
                      </span>
                    </Title>
                  </Step>
                </div>
                <div className="voucher__description">
                  Please select the voucher type
                </div>
                <div
                  className={classNames(
                    "voucher__type",
                    mode !== "create" && "voucher__type--disabled",
                  )}
                >
                  <div className="voucher__grid">
                    <div
                      className={`voucher__tile ${
                        display === VoucherType.DISCOUNT &&
                        "voucher__tile--selected"
                      }`}
                      onClick={() => {
                        if (mode === "create") {
                          dispatch(actions.resetVoucherCampaign(null));
                          setDisplay(VoucherType.DISCOUNT);
                        }
                      }}
                    >
                      <VoucherDiscountIcon className="voucher__type-icon" />

                      <div className="voucher__name">Discount Voucher</div>
                    </div>
                    <div
                      className={`voucher__tile ${
                        display === 2 && "voucher__tile--selected"
                      }`}
                      onClick={() => {
                        if (mode === "create") {
                          dispatch(actions.resetVoucherCampaign(null));
                          setDisplay(VoucherType.CASH);
                        }
                      }}
                    >
                      <VoucherCashIcon className="voucher__type-icon" />
                      <div className="voucher__name">Cash Voucher</div>
                    </div>
                    <div
                      className={`voucher__tile ${
                        display === 3 && "voucher__tile--selected"
                      }`}
                      onClick={() => {
                        if (mode === "create") {
                          dispatch(actions.resetVoucherCampaign(null));
                          setDisplay(VoucherType.GIVEAWAY);
                        }
                      }}
                    >
                      <VoucherGiveawayIcon className="voucher__type-icon" />
                      <div className="voucher__name">Giveaway</div>
                    </div>
                    <div
                      className={`voucher__tile ${
                        display === VoucherType.EVENT_PASS &&
                        "voucher__tile--selected"
                      }`}
                      onClick={() => {
                        if (mode === "create") {
                          dispatch(actions.resetVoucherCampaign(null));
                          setDisplay(VoucherType.EVENT_PASS);
                        }
                      }}
                    >
                      <VoucherEventIcon className="voucher__type-icon" />
                      <div className="voucher__name">Event Pass</div>
                    </div>
                  </div>
                  <Info_Panel>
                    For more information about vouchers, please visit our Meed
                    Academy
                  </Info_Panel>
                </div>
              </div>
              <Separator />
              <div className="voucher__choice">
                {display === VoucherType.DISCOUNT && <DiscountVoucher />}
                {display === VoucherType.CASH && <CashVoucher />}
                {display === VoucherType.GIVEAWAY && <Giveaway />}
                {display === VoucherType.EVENT_PASS && <EventPass />}
              </div>
            </fieldset>
          </div>
          {/* Second */}
          <div
            className={`voucher__section${
              focus === 2 ? " voucher__section--focus" : ""
            }${mobileStep === 1 ? " membership__section--mobile-current" : ""}`}
            onClick={() => {
              setFocus(2);
            }}
          >
            <fieldset disabled={mode === "restart"}>
              <div className="voucher-details">
                <div className="voucher__sub-heading">
                  <Step number={checkDetils()}>
                    <Title secondary>
                      <span className="nobr">
                        Details
                        <Explainer size="20">
                          Give your campaign a unique name so you can recognise
                          it
                          <br />
                          when you track its progress on the home page.🏠
                        </Explainer>
                      </span>
                    </Title>
                  </Step>
                </div>
                <div className="voucher__description voucher__description--smaller">
                  Name your voucher campaign. Write a description and upload a
                  picture.
                </div>
                <div className="voucher__widget">
                  <Label>Your campaign voucher name is:</Label>
                  <AppInput
                    disabled // can be: readonly="readonly"
                    placeholder="Enter voucher title"
                    value={campaignName()}
                    onChange={(e) => {
                      setValue({ ...value, name: e.target.value });
                    }}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    onCopy={(e) => {
                      e.preventDefault();
                    }}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    id="input-field"
                  />
                </div>
                <div className="voucher__widget">
                  <Label>Description</Label>
                  <AppInput
                    tag="textarea"
                    rows="3"
                    placeholder="Enter description (Max 2000 characters)"
                    value={value.description}
                    onChange={(e) => {
                      setValue({ ...value, description: e.target.value });
                    }}
                    id="voucher-description"
                  />
                </div>
                <div className="voucher__widget">
                  <Label>Link to Terms & Conditions (Optional)</Label>
                  <AppInput
                    placeholder="Enter Link to Terms and Conditions"
                    value={value.termsNConditionsURL}
                    onChange={(e) => {
                      setValue({
                        ...value,
                        termsNConditionsURL: e.target.value,
                      });
                    }}
                    id="input-field"
                  />
                </div>
              </div>

              <div className="voucher__widget voucher-requirements">
                <Label>
                  Requirement
                  <Explainer size="20">
                    Choose up to two conditions that apply, if necessary.
                    <br />
                    - Examples:
                    <br />
                    - Subject to availability
                    <br />
                    - Must be over 18
                    <br />- Only on Saturday and Sunday
                  </Explainer>
                </Label>
                <AppInput
                  placeholder="Condition #1"
                  id="voucher-requirement"
                  value={value.req.a}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      req: {
                        ...value.req,
                        a: e.target.value,
                      },
                    });
                  }}
                />
                <AppInput
                  style={{ marginTop: "6px" }}
                  placeholder="Condition #2"
                  value={value.req.b}
                  onChange={(e) => {
                    setValue({
                      ...value,
                      req: {
                        ...value.req,
                        b: e.target.value,
                      },
                    });
                  }}
                />
              </div>
              <div className="voucher__widget" id="upload-image">
                <Label>Campaign picture</Label>
                <Uploader
                  type="image"
                  message="Upload your campaign picture"
                  width={315}
                  height={315}
                  defaultImageSrc={data?.imageId}
                  onUploaded={(sha) => {
                    setSelectedImageSHA(sha);
                  }}
                  aspectRatio={1}
                />
                <Helper>
                  The image file should be in PNG or JPG format only, at least
                  315x315&nbsp;pixels. Do not include any Meed trademarks.
                </Helper>
              </div>
            </fieldset>
          </div>
          {/* Third */}
          <div
            className={`voucher__section${
              focus === 3 ? " voucher__section--focus" : ""
            }${mobileStep === 2 ? " membership__section--mobile-current" : ""}`}
            onClick={() => {
              setFocus(3);
            }}
          >
            <div className="voucher-schedule" id="voucher-start-date">
              <div className="voucher__sub-heading">
                <Step number={checkSchedule()}>
                  <Title secondary>
                    Set&nbsp;
                    <span className="nobr">
                      Schedule
                      <Explainer size="20">
                        Select the start and end date
                        <br />
                        between which the voucher will be valid.
                      </Explainer>
                    </span>
                  </Title>
                </Step>
              </div>
              <div className="voucher__widget">
                <div className="voucher__description voucher__description--smaller">
                  Select date range
                </div>
                <div className="date-range-picker">
                  <DayPicker
                    mode="range"
                    selected={{
                      from: value.startDate ? value.startDate : undefined,
                      to: value.endDate ? value.endDate : undefined,
                    }}
                    footer={
                      <div style={{ marginTop: "15px", textAlign: "center" }}>
                        {value.startDate
                          ? `from ${DateTime.fromJSDate(
                              value.startDate,
                            ).toLocaleString()} `
                          : ""}
                        {value.endDate
                          ? `to ${DateTime.fromJSDate(
                              value.endDate,
                            ).toLocaleString()}`
                          : ""}
                      </div>
                    }
                    onSelect={(range) => {
                      setValue((state) => ({
                        ...state,
                        startDate: range?.from
                          ? DateTime.fromJSDate(range.from)
                              .set({ hour: 0, minute: 0, second: 0 })
                              .toJSDate()
                          : null,
                        endDate: range?.to
                          ? DateTime.fromJSDate(range.to)
                              .set({ hour: 23, minute: 59, second: 59 })
                              .toJSDate()
                          : null,
                      }));
                    }}
                    // Disable day picker during edit mode. Enable it when restarting or creating
                    disabled={mode === "edit" ? true : { before: new Date() }}
                  />
                </div>
              </div>
            </div>
            <Separator />
            <fieldset disabled={mode === "restart"}>
              <TooltipContext>
                <div
                  className={`voucher__widget${
                    value.referralRewardVoucher
                      ? " voucher__widget--disabled"
                      : ""
                  }`}
                >
                  {value.referralRewardVoucher && (
                    <>
                      <div className="voucher__disabling-overlay"></div>
                      <div className="voucher__disabling-tooltip">
                        <TooltipContext>
                          <Tooltip>Disabled while Reward Voucher is on</Tooltip>
                        </TooltipContext>
                      </div>
                    </>
                  )}
                  <Label>Stamp card</Label>
                  <div className="param">
                    <div className="param__label">Activate Stamp Card</div>
                    <div className="param__widget">
                      <Switch
                        size="small"
                        disabled={
                          mode !== "create" || value.referralRewardVoucher
                        }
                        onChange={(e) => {
                          setValue({
                            ...value,
                            autoVoucher: e,

                            // Reset auto-voucher related fields when auto voucher is turned off
                            ...(!e
                              ? {
                                  ovpu: false,
                                  no_visit: 0,
                                }
                              : {}),
                          });
                        }}
                        style={{
                          background: value.autoVoucher && "var(--orange)",
                          borderColor: value.autoVoucher && "var(--orange)",
                        }}
                      />
                    </div>
                  </div>
                  {value.autoVoucher && (
                    <>
                      <div className="param">
                        <div className="param__label">
                          Number of stamps required to redeem
                        </div>
                        <div className="param__widget">
                          <select
                            id="visit"
                            value={value.no_visit}
                            disabled={mode !== "create"}
                            onChange={(e) => {
                              setValue({
                                ...value,
                                no_visit: Number(e.target.value),
                              });
                            }}
                          >
                            <option value="null">stamp</option>
                            {options_visit.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="param">
                        <div className="param__label">
                          One voucher per customer
                        </div>
                        <div className="param__widget">
                          <Switch
                            size="small"
                            disabled={mode !== "create"}
                            onChange={(e) => {
                              setValue({ ...value, ovpu: e });
                            }}
                            style={{
                              background: value.ovpu && "var(--orange)",
                              borderColor: value.ovpu && "var(--orange)",
                            }}
                          />
                        </div>
                      </div>
                      <div className="voucher__amount-hint">
                        <Info_Panel>
                          Leave off to enable auto-replacement with a new stamp
                          card once the previous one is complete.
                        </Info_Panel>
                      </div>
                      <div className="param">
                        <div className="param__label">Activate Check-in</div>
                        <div className="param__widget">
                          <Switch
                            size="small"
                            disabled={mode !== "create"}
                            onChange={(e) => {
                              setValue({ ...value, memberSelfStamping: e });
                            }}
                            style={{
                              background:
                                value.memberSelfStamping && "var(--orange)",
                              borderColor:
                                value.memberSelfStamping && "var(--orange)",
                            }}
                          />
                        </div>
                      </div>
                      <div className="param">
                        <Info_Panel>
                          Your customers will be able to check-in every 24
                          hours.
                        </Info_Panel>
                        {/* <div className="param__label">
                          Check-in cooldown cooldown
                        </div>
                        <div className="param__widget">
                          <select
                            id="visit"
                            value={value.memberSelfStampingWaitHours}
                            disabled={mode !== "create"}
                            onChange={(e) => {
                              console.log("e", e.target.value);
                              setValue({
                                ...value,
                                memberSelfStampingWaitHours: Number(
                                  e.target.value,
                                ),
                              });
                            }}
                          >
                            <option value="null">time</option>
                            {options_stampCD.map((option, index) => (
                              <option key={index} value={option}>
                                {option} hours
                              </option>
                            ))}
                                  </select>
                                  </div> */}
                      </div>
                      <div className="param">
                        <div className="param__label">
                          Receipt capture to stamps
                        </div>
                        <div className="param__widget">
                          <Switch
                            size="small"
                            disabled={mode !== "create"}
                            onChange={(e) => {
                              setValue({ ...value, memberReceiptScanning: e });
                            }}
                            style={{
                              background:
                                value.memberReceiptScanning && "var(--orange)",
                              borderColor:
                                value.memberReceiptScanning && "var(--orange)",
                            }}
                          />
                        </div>
                      </div>
                      {value.memberReceiptScanning && (
                        <div className="param">
                          <div className="param__receipt">
                            <div>
                              <div
                                className="param__receipt__label"
                                style={{ marginBottom: "5%" }}
                              >
                                Currency Settings
                              </div>
                              <button
                                className="param__receipt__button"
                                onClick={() => {
                                  setCurrencySettingsVisible(true);
                                }}
                                style={{
                                  padding: "8px 16px",
                                  backgroundColor: "var(--orange)",
                                  color: "white",
                                  border: "none",
                                  borderRadius: "4px",
                                  cursor: "pointer",
                                }}
                              >
                                Configure Currency
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </TooltipContext>
              {/* Reward voucher section, only shown when autoVoucher is off */}

              <div
                className={`voucher__widget${
                  value.autoVoucher ? " voucher__widget--disabled" : ""
                }`}
              >
                {value.autoVoucher && (
                  <>
                    <div className="voucher__disabling-overlay"></div>
                    <div className="voucher__disabling-tooltip">
                      <TooltipContext>
                        <Tooltip>Disabled while Auto-Voucher is on</Tooltip>
                      </TooltipContext>
                    </div>
                  </>
                )}
                <Label>
                  Reward Voucher
                  <Explainer size="20">
                    To provide reward for referrals
                  </Explainer>
                </Label>
                <div className="param">
                  <div className="param__label">Activate Reward Voucher</div>
                  <div className="param__widget">
                    <Switch
                      size="small"
                      disabled={mode !== "create" || value.autoVoucher}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          referralRewardVoucher: e,

                          // Reset referral-voucher related fields when referral voucher is turned off
                          ...(!e
                            ? {
                                referralRewardVoucherLevel: 0,
                              }
                            : {}),
                        });
                      }}
                      style={{
                        background:
                          value.referralRewardVoucher && "var(--orange)",
                        borderColor:
                          value.referralRewardVoucher && "var(--orange)",
                      }}
                    />
                  </div>
                </div>
                {value.referralRewardVoucher && (
                  <div className="param">
                    <div className="param__label">Number of referrals</div>
                    <div className="param__widget">
                      <select
                        id="visit"
                        value={value.referralRewardVoucherLevel}
                        disabled={mode !== "create"}
                        onChange={(e) => {
                          setValue({
                            ...value,
                            referralRewardVoucherLevel: Number(e.target.value),
                          });
                        }}
                      >
                        <option value="null">Referrals</option>
                        {options_no_of_referees.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </div>
          {/* Fourth */}
          <div
            className={`voucher__section${
              mobileStep === 3 ? " membership__section--mobile-current" : ""
            }`}
          >
            <fieldset disabled={mode === "restart"}>
              <Step>
                <span className="nobr">
                  <Title secondary>
                    <span className="nobr">
                      Preview
                      <Explainer size="20">
                        The preview section shows you what
                        <br />
                        the voucher will look like to your customers
                        <br />
                        on their phones.
                      </Explainer>
                    </span>
                  </Title>
                </span>
              </Step>
              <div className="voucher__description">
                Please check then select{" "}
                {mode === "create" ? "Start Campaign" : "Update Campaign"}
              </div>
              <div className="voucher__preview">
                <h3 className="voucher__preview-heading">
                  <Title tertiary>Banner view</Title>
                </h3>
                <div className="voucher__unit">
                  <Deal
                    background={businessBrandColor}
                    brandLogo={`${process.env.REACT_APP_CDN_URL}${businessImageName}.jpg`}
                    campaignPicture={
                      selectedImageSHA
                        ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                        : undefined
                    }
                    brandName={programName}
                    offerTitle={getTitle(display)}
                    offerValue={getValue(display)}
                    location={programLocation}
                    duration={`${calculateDuration(
                      new Date(),
                      new Date(value.startDate),
                      new Date(value.endDate),
                    )}`}
                  />
                </div>
              </div>
              <div className="voucher__preview voucher__preview--full">
                <h3 className="voucher__preview-heading">
                  <Title tertiary>Full view</Title>
                </h3>
                <div className="voucher__unit">
                  <InnerScroll>
                    <DetailedPreview>
                      <Deal
                        fullView
                        hasProgressCard
                        background={businessBrandColor}
                        brandLogo={`${process.env.REACT_APP_CDN_URL}${businessImageName}.jpg`}
                        campaignPicture={
                          selectedImageSHA
                            ? `${process.env.REACT_APP_CDN_URL}${selectedImageSHA}.jpg`
                            : undefined
                        }
                        brandName={programName}
                        offerTitle={getTitle(display)}
                        offerValue={getValue(display)}
                        location={programLocation}
                        hasProgressCard={value.autoVoucher}
                        visits={value.no_visit}
                        duration={`${calculateDuration(
                          new Date(),
                          new Date(value.endDate),
                        )} days`}
                        requirements={[value.req.a, value.req.b].filter(
                          (item) => !!item,
                        )}
                        description={value.description}
                        termsNConditions={value.termsNConditions}
                        startDate={
                          value.startDate
                            ? new Date(value.startDate)
                            : undefined
                        }
                        endDate={
                          value.endDate ? new Date(value.endDate) : undefined
                        }
                      />
                    </DetailedPreview>
                  </InnerScroll>
                </div>
              </div>
            </fieldset>
            <div className="voucher__submit">
              <AppButton
                uniqueModifier="submit"
                fullWidth
                id="cr-btn-vh"
                disabled={mode === "blocked" ? true : voucherHasExpired}
                onClick={() =>
                  handleActionNotification(
                    () => handleCreateVoucher(selected_lp),
                    mode === "restart"
                      ? "Would you like to restart the voucher campaign?"
                      : mode === "edit"
                        ? "Would you like to update the voucher campaign?"
                        : "Would you like to start the voucher campaign?",
                    "Cancel",
                    "Yes",
                  )
                }
              >
                {mode === "restart"
                  ? "Restart Campaign"
                  : mode === "edit"
                    ? "Update Campaign"
                    : "Start Campaign"}
              </AppButton>
            </div>
          </div>
        </div>
        <div className="voucher__navigation">
          <StepsNav
            current={mobileStep}
            total={totalMobileSteps}
            prevClickHandler={handleStepsNavPrevClick}
            nextClickHandler={handleStepsNavNextClick}
          />
        </div>
        <GuidedTour
          id="createVouchers"
          steps={[
            {
              content:
                "Select the type of voucher you want to create. Visit our FAQ page for more details.",
              title: "Step 1",
              target: ".voucher-type-selection",
              placementBeacon: "top",
              placement: "right",
            },
            {
              content:
                "Enter the name of the product or service you are applying the voucher no. if everything, type 'everything'!",
              title: "Step 2",
              target: ".voucher__widget:nth-child(1)",
              placementBeacon: "top",
              placement: "right",
            },
            {
              content:
                "Choose the value of your voucher (for cash and percentage discounts)",
              title: "Step 3",
              target: ".voucher__widget:nth-child(2)",
              placementBeacon: "top",
              placement: "left",
            },
            {
              content:
                "Give your campaign a unique name so you can recognise it when you track its progress on the home page.🏠",
              title: "Step 4",
              target: ".voucher-details",
              placementBeacon: "top-end",
              placement: "bottom-start",
            },
            {
              content:
                "Choose up to two conditions that apply, if necessary. Examples: Subject to availability Must be over 18 Only on Saturday and Sunday",
              title: "Step 5",
              target: ".voucher-requirements",
              placementBeacon: "top-end",
              placement: "bottom-start",
            },
            {
              content:
                "Select the start and end date between which the voucher will be valid.",
              title: "Step 6",
              target: ".voucher-schedule",
              placementBeacon: "top-end",
              placement: "left",
            },
            {
              content:
                "Auto voucher activated: If switched on, this voucher will be recieved more than one time by each user",
              title: "Step 7",
              target: ".voucher-options",
              placementBeacon: "top-end",
              placement: "left",
            },
            {
              content:
                "The preview section shows you what the voucher will look like to your customers on their phones.",
              title: "Step 8",
              target: ".voucher__section:nth-child(4)",
              placementBeacon: "top-end",
              placement: "left",
            },
          ]}
        />
      </div>

      <CurrencySettings
        visible={currencySettingsVisible}
        onClose={() => setCurrencySettingsVisible(false)}
        programUuid={selected_lp_uuid || ""}
      />
    </div>
  );
}

export default CreateVoucher_Page;
