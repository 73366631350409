import axiosInstance from "axios";

export const getCountryList = async () => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}v1/location/countries`,
      {
        withCredentials: true,
        headers: {
          "api-key": process.env.REACT_APP_API_KEY,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching country list:", error);
    throw error;
  }
};
