import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Card, Button } from "antd";
import "./LocationModal.css";
import LocationCreationModal from "../LocationCreationModal/LocationCreationModal";
import { ReactComponent as AddIcon } from "../../assets/images/add-button.svg";
import locationIcon from "../../assets/icons/location.png";
import { ProgramLocation } from "../../types";
import { getQrCodeUrlForProgramUuid } from "../../utils/utils";
import crownLogo from "../../Pages/CreateLoyalty/image/crown.png";
import { centerCrop } from "react-image-crop";
import Icon from "./Icon";

type Props = {
  onChange: (locations: ProgramLocation[]) => void;
  onClose: () => void;
  locations?: ProgramLocation[];
  open?: boolean;
  programUuid?: string;
  numberOfLocationsLimitReached: boolean;
  isHideCreate?: boolean;
};
type Methods = {
  openCreateModal: () => void;
};
const LocationsModal = forwardRef<Methods, Props>(
  (
    {
      locations,
      open,
      programUuid,
      onChange,
      onClose,
      numberOfLocationsLimitReached,
      isHideCreate,
    },
    ref,
  ) => {
    const [isViewLocationModalVisible, setIsViewLocationModalVisible] =
      useState(false);
    const [selectedLocation, setSelectedLocation] = useState<ProgramLocation>();
    useImperativeHandle(ref, () => ({
      openCreateModal() {
        setSelectedLocation(undefined);
        setIsViewLocationModalVisible(true);
      },
    }));

    const selectedUuid = localStorage.getItem("programUuid");

    console.log(locations);
    const handleQrCode = async (
      programUuid: string,
      locationId: string | number,
    ) => {
      const qrCodeUrl = await getQrCodeUrlForProgramUuid(
        programUuid,
        String(locationId),
      );
      window.open(qrCodeUrl, "_blank");
    };

    const handleScannerQrCode = (
      programUuid: string,
      locationId: string | number,
    ) => {
      const qrUrl = `${programUuid}/${locationId}`;
      window.open(
        "https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=https://dev-webapp.meedloyalty.com/CameraApp/" +
          qrUrl,
        "_blank",
      );
    };

    return (
      <>
        <Modal
          title="View all Locations"
          open={open}
          onOk={() => onClose()}
          onCancel={() => onClose()}
          footer={null}
          width={"70%"}
        >
          <div className="locations-container">
            {!isHideCreate && (
              <Card
                className={`location-card ${
                  numberOfLocationsLimitReached ? "disabled" : ""
                }`}
                onClick={() => {
                  if (!numberOfLocationsLimitReached) {
                    setSelectedLocation(undefined);
                    setIsViewLocationModalVisible(true);
                  }
                }}
              >
                <div className="create-new-location">
                  <>
                    <AddIcon />
                    <p className="create-new-text">
                      Create new business location
                    </p>
                  </>
                  {numberOfLocationsLimitReached && (
                    <div className="create-new-location">
                      <p style={{ fontSize: 12 }}>
                        Reached the maximum number of locations for free tier.
                      </p>
                      <img
                        src={crownLogo}
                        className="crown-logo"
                        alt="Crown Logo"
                      />
                    </div>
                  )}
                </div>
              </Card>
            )}
            {locations?.map((location, index) => (
              <Card key={location.id} className="location-card">
                <div className="location-content">
                  <p className="location-index">
                    Location {location.locationName}
                  </p>

                  <div className="location-title">
                    <img
                      src={`${process.env.REACT_APP_CDN_URL}${location.logoImageId}.jpg`}
                      className="location-logo"
                    ></img>
                    <p className="location-name">{location.businessName}</p>
                  </div>
                  <div className="location-location">
                    <img
                      src={locationIcon}
                      className="location-locationIcon"
                    ></img>
                    <p>{location.address}</p>
                  </div>
                </div>
                <div className="location-button">
                  {!isHideCreate && (
                    <Button
                      className="edit-button"
                      onClick={() => {
                        setSelectedLocation(location);
                        setIsViewLocationModalVisible(true);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                  <Button
                    className="qrcode-button"
                    onClick={() => handleQrCode(selectedUuid!, location.id)}
                  >
                    <Icon className="qrcode-icon" />
                    QR Code
                  </Button>
                  {/* <Button
                    className="qrcode-button"
                    onClick={() =>
                      handleScannerQrCode(selectedUuid!, location.id)
                    }
                  >
                    <Icon className="qrcode-icon" />
                    Scanner QR Code
                  </Button> */}
                </div>
              </Card>
            ))}
          </div>
        </Modal>
        {isViewLocationModalVisible && (
          <LocationCreationModal
            handleCreate={async (location) => {
              onChange((locations || [])?.concat(location));
              setIsViewLocationModalVisible(false);
            }}
            handleUpdate={async (location) => {
              onChange(
                locations?.map((x) => (x.id === location.id ? location : x)) ||
                  [],
              );
              setIsViewLocationModalVisible(false);
            }}
            programUuid={programUuid}
            handleCancel={() => {
              setSelectedLocation(undefined);
              setIsViewLocationModalVisible(false);
            }}
            data={selectedLocation}
          />
        )}
      </>
    );
  },
);

export default LocationsModal;
