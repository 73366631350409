import React, { useState } from "react";
import "./menu.css";
import { DASHBOARD_ID, DASHBOARD_URL } from "../../utils/constants";
import { useNavigate } from "react-router-dom";

type DashboardKey = keyof typeof DASHBOARD_ID;

const Menu = ({ section }: { section?: string }) => {
  const navigate = useNavigate();
  //console.log("selectedDashboard", selectedDashboard);
  return (
    <div className="menu_body">
      <button
        className={`menu_headings ${!section ? "selected" : ""}`}
        onClick={() => {
          navigate("/dashboard/looker");
        }}
      >
        Overview
      </button>
      <button
        className={`menu_headings ${
          section === "membership" ? "selected" : ""
        }`}
        onClick={() => {
          navigate("/dashboard/looker/membership");
        }}
      >
        Membership
      </button>
      <button
        className={`menu_headings ${section === "voucher" ? "selected" : ""}`}
        onClick={() => {
          navigate("/dashboard/looker/voucher");
        }}
      >
        Voucher
      </button>
      <button
        className={`menu_headings ${
          section === "stamp-card" ? "selected" : ""
        }`}
        onClick={() => {
          navigate("/dashboard/looker/stamp-card");
        }}
      >
        Stamp Card
      </button>
      <button
        className={`menu_headings ${section === "location" ? "selected" : ""}`}
        onClick={() => {
          navigate("/dashboard/looker/location");
        }}
      >
        Location
      </button>
      <button
        className={`menu_headings ${
          section === "performance" ? "selected" : ""
        }`}
        onClick={() => {
          navigate("/dashboard/looker/performance");
        }}
      >
        Performance
      </button>
    </div>
  );
};

export default Menu;
